import { FC } from 'react';

import SelectUserParcelForm from '@/components/forms/SelectUserParcelForm.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import {
  getSelectedParcelName,
  getSelectedParcelPlace,
} from '@/redux/parcel/parcel.selectors.ts';

const SelectedParcelHeader: FC = () => {
  const parcelName = useAppSelector(getSelectedParcelName);
  const parcelPlace = useAppSelector(getSelectedParcelPlace);

  return (
    <nav className="flex items-center justify-center bg-white border-b-2 w-full px-10 py-5 max-md:px-5 gap-5 text-center">
      <div className="flex flex-row max-md:flex-col max-md:w-[50%] md:gap-2 max-md:items-start">
        <div className="text-3xl font-bold text-center flex flex-col justify-center max-md:text-xl">
          {parcelName}
        </div>
        <div className="text-3xl font-light text-center flex flex-col justify-center max-md:text-xl">
          {parcelPlace}
        </div>
      </div>
      <SelectUserParcelForm />
    </nav>
  );
};

export default SelectedParcelHeader;

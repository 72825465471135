import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Irrigation } from '@/redux/irrigation/irrigation.types.ts';

const irrigationSliceInitialState = {
  scheduledIrrigation: [],
};

const irrigationSlice = createSlice({
  name: 'irrigationSlice',
  initialState: irrigationSliceInitialState,
  reducers: {
    setScheduledIrrigation: (state, action: PayloadAction<Irrigation[]>) => {
      state.scheduledIrrigation = action.payload;
    },
    removeIrrigationById: (state, action: PayloadAction<Irrigation>) => {
      state.scheduledIrrigation = state.scheduledIrrigation.filter(
        (irrigation) => irrigation.id !== action.payload.id
      );
    },
  },
});

export const { setScheduledIrrigation, removeIrrigationById } =
  irrigationSlice.actions;
export default irrigationSlice.reducer;

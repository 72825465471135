import { FC, useEffect } from 'react';

import ResetPasswordForm from '@/components/forms/ResetPasswordForm.tsx';
import LoginPageLeftSection from '@/components/layout/login/LoginPageLeftSection.tsx';
import { config } from '@/config/config.ts';
import { useQuery } from '@/hooks/global/useQuery.ts';

const ResetPasswordPage: FC = () => {
  const query = useQuery();

  useEffect(() => {
    if (query.get('isMobile') && query.get('token')) {
      const nativeAppName = config.mobileApp.name;
      window.location.replace(
        `${nativeAppName}/reset-password?token=${query.get('token')}`
      );
    }
  }, [query]);

  return (
    <div className="h-screen">
      <div className="bg-[url('/loginBg.jpg')] fixed top-0 left-0 w-full h-screen bg-cover bg-center bg-no-repeat -z-10 blur-[10px]" />
      <div className="flex flex-1 flex-row w-screen justify-center items-center h-full">
        <div className="flex flex-row max-md:flex-col max-md:items-center self-center md:min-w-[1000px] max-md:mx-5 max-md:w-full md:min-h-[800px]">
          <LoginPageLeftSection />
          <div className="md:w-[50%] max-md:w-full bg-white flex justify-center items-center">
            <ResetPasswordForm />
          </div>
        </div>
      </div>
      <footer className="sticky bottom-0 h-70px text-white text-center">
        <span>© 2024 Smart Watering</span>
      </footer>
    </div>
  );
};

export default ResetPasswordPage;

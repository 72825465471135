import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';
import { Switch } from '@/components/ui/switch.tsx';

type CustomFormSwitchProps = {
  customForm: UseFormReturn<any>;
  name: string;
  labelText: string;
  formItemStyle?: string;
  labelStyle?: string;
};

const CustomFormSwitch: FC<CustomFormSwitchProps> = ({
  formItemStyle,
  customForm,
  name,
  labelText,
  labelStyle,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className={formItemStyle}>
            <FormLabel className={labelStyle}>{labelText}</FormLabel>
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
          </FormItem>
        );
      }}
    />
  );
};

export default CustomFormSwitch;

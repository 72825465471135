import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link } from 'react-router-dom';

type AddNewIconProps = {
  link: string;
};

const AddNewIcon: FC<AddNewIconProps> = ({ link }) => {
  return (
    <Link
      to={link}
      className="bg-[#33b35a] fixed bottom-[30px] right-[30px] max-md:bottom-[75px] max-md:right-[20px] rounded-full p-5 flex items-center"
    >
      <FontAwesomeIcon
        icon={faPlus}
        color="white"
        className="w-[40px] h-[40px] max-md:w-[20px] max-md:h-[20px]"
      />
    </Link>
  );
};

export default AddNewIcon;

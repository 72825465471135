import { FC } from 'react';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import SavedProgramsSection from '@/components/layout/home/SavedProgramsSection.tsx';
import ScheduledIrrigationSection from '@/components/layout/home/ScheduledIrrigationSection.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import SelectedParcelHeader from '@/components/layout/SelectedParcelHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const IrrigationPage: FC = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <div className="flex w-[88%] bg-[#85b4f2] flex-col bg-opacity-10 max-md:w-full">
          <SelectedParcelHeader />
          <ScheduledIrrigationSection />
          <SavedProgramsSection />
        </div>
      </div>
      <AddNewIcon link="/irrigation/create" />
      <MobileFooterNavigation />
    </div>
  );
};

export default IrrigationPage;

import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useQuery } from '@/hooks/global/useQuery.ts';
import {
  adminLoginUser,
  distributorLoginUser,
} from '@/redux/auth/auth.actions.ts';
import { getIsLoggedIn } from '@/redux/auth/auth.selectors.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getParcelNameIdAndPlaceForUser } from '@/redux/parcel/parcel.actions.ts';
import { GetUserParcelsForDropdown } from '@/redux/parcel/parcel.types.ts';

const AuthenticatedRoutes: FC<PropsWithChildren> = () => {
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const params = useQuery();
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetUserParcelsForDropdown) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error('Error while fetching parcels for user');
  };

  // eslint-disable-next-line consistent-return
  const loginUserAdmin = async () => {
    const data = {
      email: params.get('email'),
      adminToken: params.get('token'),
    };

    // @ts-ignore
    const response = await dispatch(adminLoginUser(data)).unwrap();
    if (!response.success) {
      toast.error('Error while opening user app. Please login');
      return <Navigate to="/login" />;
    }

    router('/');
  };

  // eslint-disable-next-line consistent-return
  const loginUserDistributor = async () => {
    const data = {
      email: params.get('email'),
      distributorToken: params.get('token'),
    };

    // @ts-ignore
    const response = await dispatch(distributorLoginUser(data)).unwrap();
    if (!response.success) {
      toast.error('Error while opening user app. Please login');
      return <Navigate to="/login" />;
    }

    router('/');
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUserParcels = async () => {
        // @ts-ignore
        const response = await dispatch(
          getParcelNameIdAndPlaceForUser(localStorage.getItem('id'))
        ).unwrap();

        if (!response.success) {
          handleErrorResponse(response);
        }
      };

      fetchUserParcels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (params.get('email') && params.get('token') && params.get('isAdmin')) {
    loginUserAdmin();
    return null;
  }

  if (
    params.get('email') &&
    params.get('token') &&
    params.get('isDistributor')
  ) {
    loginUserDistributor();
    return null;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default AuthenticatedRoutes;

import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmDialog from '@/components/forms/program/ConfirmDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  deleteIrrigation,
  getScheduledIrrigationAction,
} from '@/redux/irrigation/irrigation.actions.ts';
import { getScheduledIrrigation } from '@/redux/irrigation/irrigation.selectors.ts';
import {
  CreateIrrigationResponse,
  GetScheduledIrrigationResponse,
  Irrigation,
  IrrigationType,
} from '@/redux/irrigation/irrigation.types.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import { formatDateForIrrigation } from '@/utils/dateUtil.ts';

type SingleIrrigationProps = {
  irrigation: Irrigation;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

type ScheduledIrrigationProps = {
  irrigation: Irrigation;
};

const ZoneIrrigation: FC<SingleIrrigationProps> = ({
  irrigation,
  setIsDialogOpen,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className="w-full bg-white mt-5 shadow-lg flex flex-row justify-between px-2 py-5 max-md:flex-col">
      <div className="flex flex-col md:min-w-[225px]">
        <p className="text-lg font-bold">{t('zoneIrrigation')}</p>
        {irrigation.shifts.map((shift) => (
          <span
            className="text-sm"
            key={`irrigation-${irrigation.id}-shift-${shift.shiftId}`}
          >
            {`${t('zone')} ${shift.zone}`}
            {': '}
            {`${shift.waterLevel} m3`}
          </span>
        ))}
      </div>
      <p className="flex flex-col justify-center text-sm text-center max-md:justify-start max-md:text-end">
        {formatDateForIrrigation(irrigation.dateAndTimeOfIrrigation)}
      </p>
      <div className="md:border-l md:border-l-[##868e96] md:pr-40 flex flex-col justify-center">
        <Button
          className="bg-white flex flex-row gap-1 text-red-500 md:ml-5 text-sm"
          onClick={() => setIsDialogOpen(true)}
        >
          <FontAwesomeIcon icon={faTimes} />
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};

const TimeIrrigation: FC<SingleIrrigationProps> = ({
  irrigation,
  setIsDialogOpen,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className="w-full bg-white mt-5 flex flex-row justify-between shadow-lg px-2 py-5 max-md:flex-col">
      <div className="flex flex-col md:min-w-[225px]">
        <p className="text-lg font-bold">{t('timeIrrigation')}</p>
        <p className="text-sm font-light flex flex-row gap-2 items-center">
          <FontAwesomeIcon icon={faClock} />
          {`${irrigation.workTimeOfIrrigation} min`}
        </p>
      </div>
      <p className="flex flex-col justify-center text-sm text-center max-md:justify-start max-md:text-end">
        {formatDateForIrrigation(irrigation.dateAndTimeOfIrrigation)}
      </p>
      <div className="md:border-l md:border-l-[##868e96] md:pr-40 flex flex-col justify-center">
        <Button
          className="bg-white flex flex-row gap-1 text-red-500 md:ml-5 text-sm"
          onClick={() => setIsDialogOpen(true)}
        >
          <FontAwesomeIcon icon={faTimes} />
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};

const ScheduledIrrigation: FC<ScheduledIrrigationProps> = ({ irrigation }) => {
  const { t } = useTranslation('translation');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onDiscardClick = () => {
    setIsDialogOpen(false);
  };

  const handleErrorResponse = (response: CreateIrrigationResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error('Error while fetching parcels for user');
  };
  const onContinueClick = async () => {
    // @ts-ignore
    const result = await dispatch(deleteIrrigation(irrigation.id)).unwrap();

    if (!result.success) {
      handleErrorResponse(result);
      return;
    }

    toast.success(t('successfulIrrigationDeletion'));
    setIsDialogOpen(false);
  };

  return (
    <>
      {irrigation.irrigationType === IrrigationType.M3 && (
        <ZoneIrrigation
          irrigation={irrigation}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
      {irrigation.irrigationType === IrrigationType.TIME && (
        <TimeIrrigation
          irrigation={irrigation}
          setIsDialogOpen={setIsDialogOpen}
        />
      )}
      <ConfirmDialog
        headerText={t('deleteIrrigation')}
        descriptionText={t('deleteIrrigationDesc')}
        onDiscardClick={onDiscardClick}
        onContinueClick={onContinueClick}
        setIsDialogOpen={setIsDialogOpen}
        isDialogOpen={isDialogOpen}
        confirmText={t('yes')}
        discardText={t('no')}
        confirmStyle="bg-[#dc3545] py-3 px-5 text-white"
      />
    </>
  );
};

const ScheduledIrrigationSection: FC = () => {
  const parcelId = useAppSelector(getSelectedParcelId);
  const scheduledIrrigation = useAppSelector(getScheduledIrrigation);
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const { t } = useTranslation('translation');

  const handleErrorResponse = (response: GetScheduledIrrigationResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error('Error while fetching scheduled irrigation');
  };

  useEffect(() => {
    if (!parcelId) {
      return;
    }
    const fetchScheduledIrrigation = async () => {
      // @ts-ignore
      const response = await dispatch(
        getScheduledIrrigationAction(parcelId)
      ).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchScheduledIrrigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <div className="w-full p-10 max-md:p-5">
      <h2 className="text-xl font-bold">{t('scheduledIrrigation')}</h2>
      {scheduledIrrigation.map((irrigation, index) => (
        <ScheduledIrrigation
          irrigation={irrigation}
          key={`${irrigation.id}-scheduled-irrigation-${index + 1}`}
        />
      ))}
    </div>
  );
};

export default ScheduledIrrigationSection;

import './App.css';

import 'react-toastify/dist/ReactToastify.css';

import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthenticatedRoutes from '@/components/router/AuthenticatedRoutes.tsx';
import NotAuthenticatedRoutes from '@/components/router/NotAuthenticatedRoutes.tsx';
import CreateIrrigationPage from '@/pages/CreateIrrigationPage.tsx';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage.tsx';
import HomePage from '@/pages/HomePage.tsx';
import IrrigationPage from '@/pages/IrrigationPage.tsx';
import LoginPage from '@/pages/LoginPage.tsx';
import NotFoundPage from '@/pages/NotFoundPage.tsx';
import ResetPasswordPage from '@/pages/ResetPasswordPage.tsx';

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route element={<AuthenticatedRoutes />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/irrigation" element={<IrrigationPage />} />
            <Route
              path="/irrigation/create"
              element={<CreateIrrigationPage />}
            />
          </Route>
          <Route element={<NotAuthenticatedRoutes />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useAppDispatch } from '@/redux/hooks.ts';
import { Irrigation } from '@/redux/irrigation/irrigation.types.ts';
import { createProgram } from '@/redux/program/program.actions.ts';

type SaveAsProgramDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  createdIrrigation: Irrigation;
};

type ProgramFormData = {
  programName: string;
  irrigation: Irrigation;
  createdIrrigation;
};

const SaveAsProgramDialog: FC<SaveAsProgramDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  createdIrrigation,
}) => {
  const { t } = useTranslation('translation');
  const programForm = useForm({
    defaultValues: {
      programName: '',
      irrigation: createdIrrigation,
    },
  });
  const router = useNavigate();
  const dispatch = useAppDispatch();

  const onDialogClose = () => {
    setIsDialogOpen(false);

    toast.success(t('successfulSchedule'));
    router(-1);
  };

  const onSubmit = async (data: ProgramFormData) => {
    // @ts-ignore
    const response = await dispatch(createProgram(data)).unwrap();

    if (!response.success) {
      toast.error(response.error.message);
      return;
    }

    onDialogClose();
  };

  useEffect(() => {
    programForm.setValue('irrigation', createdIrrigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdIrrigation]);

  return (
    <Dialog open={isDialogOpen} onOpenChange={onDialogClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('saveProgram').toUpperCase()}</DialogTitle>
          <DialogDescription>{t('createProgramDesc')}</DialogDescription>
        </DialogHeader>
        <Form
          reset={programForm.reset}
          formState={programForm.formState}
          clearErrors={programForm.clearErrors}
          control={programForm.control}
          getFieldState={programForm.getFieldState}
          getValues={programForm.getValues}
          handleSubmit={programForm.handleSubmit}
          register={programForm.register}
          resetField={programForm.resetField}
          setError={programForm.setError}
          setFocus={programForm.setFocus}
          setValue={programForm.setValue}
          trigger={programForm.trigger}
          unregister={programForm.unregister}
          watch={programForm.watch}
        >
          <form
            className="grid gap-4 py-4"
            onSubmit={programForm.handleSubmit(onSubmit)}
          >
            <CustomFormInput
              customForm={programForm}
              name="programName"
              labelText={t('programName')}
              formItemStyle="flex flex-col w-full gap-1"
              inputStyle="px-2 py-3"
              labelStyle="w-full"
              divStyle="w-full"
              type="text"
            />
            <DialogFooter>
              <Button
                type="submit"
                className="mt-4 bg-[#33b35a] py-3 px-5 text-white"
              >
                {t('saveProgram')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default SaveAsProgramDialog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ParcelNameIdAndPlace } from '@/redux/parcel/parcel.types.ts';

const parcelSliceInitialState = {
  selectedParcelId: '',
  selectedParcelName: '',
  selectedParcelPlace: '',
  selectedParcelNumberOfZones: 0,
  userDropdownParcels: [],
};

const parcelSlice = createSlice({
  name: 'parcelSlice',
  initialState: parcelSliceInitialState,
  reducers: {
    setParcel: (state, action: PayloadAction<ParcelNameIdAndPlace>) => {
      state.selectedParcelId = action.payload.id;
      state.selectedParcelName = action.payload.name;
      state.selectedParcelPlace = action.payload.place;
      state.selectedParcelNumberOfZones = action.payload.numberOfZones;
    },
    setUserDropdownParcels: (
      state,
      action: PayloadAction<ParcelNameIdAndPlace[]>
    ) => {
      state.userDropdownParcels = action.payload;
    },
  },
});

export const { setParcel, setUserDropdownParcels } = parcelSlice.actions;
export default parcelSlice.reducer;

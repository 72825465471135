import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  setParcel,
  setUserDropdownParcels,
} from '@/redux/parcel/parcel.slice.ts';
import { GetUserParcelsForDropdown } from '@/redux/parcel/parcel.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getParcelNameIdAndPlaceForUser = createAsyncThunk<
  GetUserParcelsForDropdown,
  string
>('user/parcel-get-parcel-dropdown', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetUserParcelsForDropdown>(
      `/user/parcel/get-parcels-dropdown?userId=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setUserDropdownParcels(axiosResponse.data.content));

    if (!axiosResponse.data.content.length) {
      return axiosResponse.data;
    }

    dispatch(setParcel(axiosResponse.data.content[0]));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export { getParcelNameIdAndPlaceForUser };

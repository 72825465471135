import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateIrrigationResponse } from '@/redux/irrigation/irrigation.types.ts';
import {
  removeDeletedProgram,
  setPrograms,
} from '@/redux/program/program.slice.ts';
import {
  CreateProgramData,
  CreateProgramResponse,
  GetProgramsResponse,
} from '@/redux/program/program.types.ts';
import axiosServer from '@/services/axios.service.ts';

const createProgram = createAsyncThunk<
  CreateProgramResponse,
  CreateProgramData
>('user/create-program', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateIrrigationResponse>(
      `/program/create-program`,
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getProgramsForParcel = createAsyncThunk<GetProgramsResponse, string>(
  'user/get-programs-by-parcel',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetProgramsResponse>(
        `/program/get-by-parcel?parcelId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setPrograms(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const deleteSavedProgram = createAsyncThunk<CreateProgramResponse, string>(
  'user/delete-saved-programs',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateProgramResponse>(
        `/program/delete?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(removeDeletedProgram(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export { createProgram, getProgramsForParcel, deleteSavedProgram };

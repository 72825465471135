import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { userLogin } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { loginSchema } from '@/zod/types.ts';

export type LoginFormData = {
  email: string;
  password: string;
};

const LoginForm: FC = () => {
  const { t } = useTranslation('translation');
  const loginForm = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onSubmit = async (data: LoginFormData) => {
    // @ts-ignore
    const response = await dispatch(userLogin(data)).unwrap();

    if (!response.success) {
      loginForm.setError('password', { message: t('wrongCredentials' as any) });
      return;
    }

    router('/');
  };

  const onForgotPasswordClick = () => {
    router('/forgot-password');
  };

  return (
    <Form
      reset={loginForm.reset}
      formState={loginForm.formState}
      clearErrors={loginForm.clearErrors}
      control={loginForm.control}
      getFieldState={loginForm.getFieldState}
      getValues={loginForm.getValues}
      handleSubmit={loginForm.handleSubmit}
      register={loginForm.register}
      resetField={loginForm.resetField}
      setError={loginForm.setError}
      setFocus={loginForm.setFocus}
      setValue={loginForm.setValue}
      trigger={loginForm.trigger}
      unregister={loginForm.unregister}
      watch={loginForm.watch}
    >
      <form
        className="flex flex-col md:gap-6 pl-5 max-md:p-5 w-full"
        onSubmit={loginForm.handleSubmit(onSubmit)}
      >
        <CustomFormInput
          customForm={loginForm}
          name="email"
          labelText={t('email')}
          formItemStyle="flex flex-col max-md:flex-col w-full max-md:py-5"
          labelStyle="hidden"
          divStyle="max-md:w-full w-[85%]"
          type="text"
          placeholder={t('email')}
          inputStyle="border-t-0 border-x-0 border-b-[1px] focus-visible:border-b-[#28a745] rounded-[0px] ring-[0px] focus-visible:ring-0"
        />
        <CustomFormInput
          customForm={loginForm}
          name="password"
          labelText={t('password')}
          formItemStyle="flex flex-col max-md:flex-col w-full max-md:py-5"
          labelStyle="hidden"
          divStyle="max-md:w-full w-[85%]"
          type="password"
          placeholder={t('password')}
          inputStyle="border-t-0 border-x-0 border-b-[1px] focus-visible:border-b-[#28a745] rounded-[0px] ring-[0px] focus-visible:ring-0"
        />
        <div className="flex flex-row max-md:flex-col md:items-start md:justify-between">
          <Button className="bg-[#28a745] py-3 px-5 text-white" type="submit">
            {t('login')}
          </Button>
          <Button
            className="bg-transparent text-[#28a745] pr-16 max-md:mt-5 max-md:text-start max-md:items-start max-md:justify-start"
            type="button"
            onClick={onForgotPasswordClick}
          >
            {t('forgotPassword')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;

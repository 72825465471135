import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';

export enum IrrigationType {
  M3 = 'm3',
  TIME = 'time',
}

export const irrigationTypes: DropDownItems[] = [
  {
    value: 'm3',
    text: 'm3',
  },
  {
    value: 'time',
    text: 'time',
  },
];

export const getZonesForNumberOfZones = (numberOfZones: number) => {
  const zones: DropDownItems[] = [];
  for (let i = 0; i < numberOfZones; i += 1) {
    zones.push({
      value: `${i + 1}`,
      text: `Zona ${i + 1}`,
    });
  }

  return zones;
};

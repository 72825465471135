import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Button } from '@/components/ui/button.tsx';
import { Calendar } from '@/components/ui/calendar.tsx';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { TimePicker } from '@/components/ui/time-picker.tsx';
import { cn } from '@/lib/utils.ts';

type CustomFormDateTimePickerProps = {
  form: UseFormReturn<any>;
  name: string;
  labelText?: string;
  placeholder?: string;
  formItemStyle?: string;
  labelStyle?: string;
  buttonStyle?: string;
};

const CustomFormDateTimePicker: FC<CustomFormDateTimePickerProps> = ({
  form,
  name,
  labelText,
  placeholder,
  formItemStyle,
  labelStyle,
  buttonStyle,
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <Popover>
            <FormControl>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    buttonStyle,
                    !field.value && 'text-muted-foreground'
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP HH:mm:ss')
                  ) : (
                    <span>{placeholder}</span>
                  )}
                </Button>
              </PopoverTrigger>
            </FormControl>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                initialFocus
              />
              <div className="p-3 border-t border-border">
                <TimePicker setDate={field.onChange} date={field.value} />
              </div>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CustomFormDateTimePicker;

import { combineReducers } from '@reduxjs/toolkit';

import authSlice from '@/redux/auth/auth.slice.ts';
import irrigationSlice from '@/redux/irrigation/irrigation.slice.ts';
import parcelSlice from '@/redux/parcel/parcel.slice.ts';
import programSlice from '@/redux/program/program.slice.ts';

const reducer = combineReducers({
  auth: authSlice,
  parcel: parcelSlice,
  irrigation: irrigationSlice,
  program: programSlice,
});

export default { reducer };

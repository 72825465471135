import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';

type CustomFormInputProps = {
  customForm: UseFormReturn<any>;
  name: string;
  labelText: string;
  type: string;
  formItemStyle?: string;
  labelStyle?: string;
  divStyle?: string;
  inputStyle?: string;
  placeholder?: string;
};

const CustomFormInput: FC<CustomFormInputProps> = ({
  customForm,
  name,
  labelStyle,
  labelText,
  divStyle,
  formItemStyle,
  type,
  inputStyle,
  placeholder,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <div className={divStyle}>
            <FormControl className="w-full focus:outline-none">
              <Input
                {...field}
                type={type}
                className={inputStyle}
                placeholder={placeholder}
              />
            </FormControl>
            <FormMessage className="mt-2" />
          </div>
        </FormItem>
      )}
    />
  );
};

export default CustomFormInput;

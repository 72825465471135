import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { userForgotPassword } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { forgotPasswordSchema } from '@/zod/types.ts';

export type ForgotPasswordFormData = {
  email: string;
};

type ForgotPasswordFormContentProps = {
  forgotPasswordForm: UseFormReturn<any, any, any>;
  onSubmit: (data: ForgotPasswordFormData) => void;
};

const ForgotPasswordFormContent: FC<ForgotPasswordFormContentProps> = ({
  forgotPasswordForm,
  onSubmit,
}) => {
  const { t } = useTranslation('translation');

  return (
    <Form
      reset={forgotPasswordForm.reset}
      formState={forgotPasswordForm.formState}
      clearErrors={forgotPasswordForm.clearErrors}
      control={forgotPasswordForm.control}
      getFieldState={forgotPasswordForm.getFieldState}
      getValues={forgotPasswordForm.getValues}
      handleSubmit={forgotPasswordForm.handleSubmit}
      register={forgotPasswordForm.register}
      resetField={forgotPasswordForm.resetField}
      setError={forgotPasswordForm.setError}
      setFocus={forgotPasswordForm.setFocus}
      setValue={forgotPasswordForm.setValue}
      trigger={forgotPasswordForm.trigger}
      unregister={forgotPasswordForm.unregister}
      watch={forgotPasswordForm.watch}
    >
      <form
        className="flex flex-col gap-6 pl-5 max-md:p-5 w-full"
        onSubmit={forgotPasswordForm.handleSubmit(onSubmit)}
      >
        <CustomFormInput
          customForm={forgotPasswordForm}
          name="email"
          labelText={t('email')}
          formItemStyle="flex flex-col max-md:flex-col w-full max-md:py-5"
          labelStyle="hidden"
          divStyle="max-md:w-full w-[85%]"
          type="text"
          placeholder={t('email')}
          inputStyle="border-t-0 border-x-0 border-b-[1px] focus-visible:border-b-[#28a745] rounded-[0px] ring-[0px] focus-visible:ring-0"
        />
        <div>
          <Button className="bg-[#28a745] py-3 px-5 text-white" type="submit">
            {t('passwordResetEmail')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation('translation');
  const [isMailSent, setIsMailSent] = useState(false);
  const forgotPasswordForm = useForm<ForgotPasswordFormData>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = async (data: ForgotPasswordFormData) => {
    // @ts-ignore
    const response = await dispatch(userForgotPassword(data)).unwrap();

    if (!response.success) {
      toast.error(response.error.message);
      return;
    }

    setIsMailSent(true);
  };

  const sendAgainClick = async () => {
    await onSubmit(forgotPasswordForm.getValues());
    toast.success(t('emailResent'));
  };

  return (
    <>
      {!isMailSent && (
        <ForgotPasswordFormContent
          forgotPasswordForm={forgotPasswordForm}
          onSubmit={onSubmit}
        />
      )}
      {isMailSent && (
        <div className="px-5 flex flex-col gap-1 flex-1">
          <h1 className="text-[#28a745] text-xl">
            {t('passwordResetMessage')}
          </h1>
          <span className="mt-10 text-base text-[#28a745]">
            {t('notReceivedEmail')}
          </span>
          <div>
            <Button
              className="bg-[#28a745] py-3 px-5 text-white mt-3"
              type="button"
              onClick={sendAgainClick}
            >
              {t('resendEmail')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordForm;

import { FC } from 'react';

import i18n from '@/i18n.ts';

const LanguageSwitcher: FC = () => {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex flex-row gap-2 pb-20 max-md:pb-5 font-sm">
      <span className="cursor-pointer" onClick={() => changeLanguage('sr')}>
        <span>🇷🇸</span>
      </span>
      <span className="cursor-pointer" onClick={() => changeLanguage('en')}>
        <span>🇬🇧</span>
      </span>
      <span className="cursor-pointer" onClick={() => changeLanguage('es')}>
        <span>🇪🇸</span>
      </span>
      <span className="cursor-pointer" onClick={() => changeLanguage('de')}>
        <span>🇩🇪</span>
      </span>
      <span className="cursor-pointer" onClick={() => changeLanguage('sk')}>
        <span>🇸🇰</span>
      </span>
      <span className="cursor-pointer" onClick={() => changeLanguage('sq')}>
        <span>🇦🇱</span>
      </span>
    </div>
  );
};

export default LanguageSwitcher;

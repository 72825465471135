import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDateTimePicker from '@/components/controls/CustomFormDateTimePicker.tsx';
import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import MixerForm from '@/components/forms/irrigation/MixerForm.tsx';
import ShiftsFormSection from '@/components/forms/irrigation/ShiftsFormSection.tsx';
import SupplementForm from '@/components/forms/irrigation/SupplementForm.tsx';
import ConfirmDialog from '@/components/forms/program/ConfirmDialog.tsx';
import SaveAsProgramDialog from '@/components/forms/program/SaveAsProgramDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createIrrigation } from '@/redux/irrigation/irrigation.actions.ts';
import { Irrigation } from '@/redux/irrigation/irrigation.types.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import { IrrigationType, irrigationTypes } from '@/utils/irrigation.utils.ts';
import { irrigationFormSchema } from '@/zod/types.ts';

export type IrrigationFormData = {
  parcelId: string;
  irrigationType: IrrigationType | '';
  dateAndTimeOfIrrigation: Date;
  workTimeOfIrrigation: number;
  shifts: { shiftId: number; zone: string; waterLevel: number }[];
  mixerEnabled: boolean;
  mixerStartDateTime: Date;
  mixerWorkTime: number;
  supplementOneEnabled: boolean;
  supplementOneDateTime: Date;
  supplementOneWorkTime: number;
  pH: number;
  supplementTwoEnabled: boolean;
  supplementTwoDateTime: Date;
  supplementTwoWorkTime: number;
  supplementTwoEC: number;
  supplementThreeEnabled: boolean;
  supplementThreeDateTime: Date;
  supplementThreeWorkTime: number;
  supplementThreeEC: number;
  supplementFourEnabled: boolean;
  supplementFourDateTime: Date;
  supplementFourWorkTime: number;
  supplementFourEC: number;
};

type SupplementFormSectionProps = {
  form: UseFormReturn<any>;
};

const SupplementFormSection: FC<SupplementFormSectionProps> = ({ form }) => {
  return (
    <>
      <SupplementForm
        form={form}
        index={1}
        enableName="supplementOneEnabled"
        startDateName="supplementOneDateTime"
        workTimeName="supplementOneWorkTime"
        inputFieldName="pH"
        inputFieldLabelName="pH"
      />
      <SupplementForm
        form={form}
        index={2}
        enableName="supplementTwoEnabled"
        startDateName="supplementTwoDateTime"
        workTimeName="supplementTwoWorkTime"
        inputFieldName="supplementTwoEC"
        inputFieldLabelName="EC"
      />
      <SupplementForm
        form={form}
        index={3}
        enableName="supplementThreeEnabled"
        startDateName="supplementThreeDateTime"
        workTimeName="supplementThreeWorkTime"
        inputFieldName="supplementThreeEC"
        inputFieldLabelName="EC"
      />
      <SupplementForm
        form={form}
        index={4}
        enableName="supplementFourEnabled"
        startDateName="supplementFourDateTime"
        workTimeName="supplementFourWorkTime"
        inputFieldName="supplementFourEC"
        inputFieldLabelName="EC"
      />
    </>
  );
};

const CreateIrrigationForm: FC = () => {
  const parcelId = useAppSelector(getSelectedParcelId);
  const { t } = useTranslation('translation');
  const [isInM3, setIsInM3] = useState(false);
  const form = useForm<IrrigationFormData>({
    resolver: zodResolver(irrigationFormSchema),
    defaultValues: {
      parcelId,
      irrigationType: '',
      dateAndTimeOfIrrigation: new Date(),
      workTimeOfIrrigation: 0,
      shifts: [],
      mixerEnabled: false,
      mixerStartDateTime: new Date(),
      mixerWorkTime: 0,
      supplementOneEnabled: false,
      supplementOneDateTime: new Date(),
      supplementOneWorkTime: 0,
      pH: 0,
      supplementTwoEnabled: false,
      supplementTwoDateTime: new Date(),
      supplementTwoWorkTime: 0,
      supplementTwoEC: 0,
      supplementThreeEnabled: false,
      supplementThreeDateTime: new Date(),
      supplementThreeWorkTime: 0,
      supplementThreeEC: 0,
      supplementFourEnabled: false,
      supplementFourDateTime: new Date(),
      supplementFourWorkTime: 0,
      supplementFourEC: 0,
    },
  });
  const { fields, append, remove } = useFieldArray<
    IrrigationFormData,
    'shifts'
  >({
    control: form.control,
    name: 'shifts',
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [savedIrrigation, setSavedIrrigation] = useState<Irrigation>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [saveAsProgramDialogOpen, setSaveAsProgramDialogOpen] = useState(false);

  const onIrrigationTypeChange = (type: string) => {
    if (type === IrrigationType.M3) {
      setIsInM3(true);
      // @ts-ignore
      form.setValue('shifts', [{ shiftId: 1, zone: '', waterLevel: '' }]);
      return;
    }

    setIsInM3(false);
    // @ts-ignore
    form.setValue('shifts', []);
  };

  const onSubmit = async (data: IrrigationFormData) => {
    // @ts-ignore
    const response = await dispatch(createIrrigation(data)).unwrap();

    if (!response.success) {
      toast.error(t('errorIrrigation'));
      return;
    }

    setSavedIrrigation(response.content);
    setConfirmDialogOpen(true);
  };

  const onDiscardClick = () => {
    setConfirmDialogOpen(false);
    toast.success(t('successfulSchedule'));
    router(-1);
  };

  const onConfirmProgramClick = () => {
    setConfirmDialogOpen(false);
    setSaveAsProgramDialogOpen(true);
  };

  useEffect(() => {
    // @ts-ignore
    form.setValue('parcelId', parcelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form
          className="flex items-start gap-6 p-10 max-md:p-5"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full shadow-lg">
            <nav className="flex itemst-start bg-white border-b-2 w-full px-5 py-3">
              <div className="text-xl font-bold">Zakaži navodnjavanje</div>
            </nav>
            <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
              <div className="flex flex-col">
                <CustomFormDateTimePicker
                  form={form}
                  name="dateAndTimeOfIrrigation"
                  labelText={t('dateAndTimeIrrigation')}
                  formItemStyle="flex flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5 gap-2"
                  labelStyle="max-md:w-full"
                  buttonStyle="justify-start text-left font-normal bg-white py-3 px-2 flex w-[40%] max-md:mt-2 max-md:w-full"
                />
                <div className="flex flex-row max-md:flex-col border-b-2 w-full border-dotted pb-10 max-md:py-5 gap-2 md:mt-5">
                  <CustomFormDropDown
                    customForm={form}
                    name="irrigationType"
                    labelText={t('irrigationType')}
                    formItemStyle="w-[50%] flex flex-col gap-1 max-md:w-full"
                    labelStyle="max-md:w-full"
                    items={irrigationTypes}
                    onChangeFunction={onIrrigationTypeChange}
                  />
                  {form.getValues('irrigationType') && !isInM3 && (
                    <CustomFormInput
                      customForm={form}
                      name="workTimeOfIrrigation"
                      labelText={t('timeInMinutes')}
                      formItemStyle="flex flex-col w-[50%] gap-1"
                      inputStyle="px-2 py-3"
                      labelStyle="w-full"
                      divStyle="w-full"
                      type="text"
                    />
                  )}
                </div>
                {isInM3 && (
                  <ShiftsFormSection
                    form={form}
                    fields={fields}
                    append={append}
                    remove={remove}
                  />
                )}
                <MixerForm form={form} />
                {form.getValues('irrigationType') && !isInM3 && (
                  <SupplementFormSection form={form} />
                )}
                <div className="flex flex-row max-md:flex-col w-full py-10">
                  <div className="w-full max-md:w-full flex flex-row gap-5">
                    <Button
                      className="bg-[#33b35a] py-3 px-2 text-white"
                      type="submit"
                    >
                      {t('scheduleIrrigation')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
      <ConfirmDialog
        headerText={t('saveProgram')}
        descriptionText={t('saveProgramQuestion')}
        onContinueClick={onConfirmProgramClick}
        onDiscardClick={onDiscardClick}
        setIsDialogOpen={setConfirmDialogOpen}
        isDialogOpen={confirmDialogOpen}
        confirmText={t('yes')}
        discardText={t('no')}
        confirmStyle="bg-[#33b35a] py-3 px-5 text-white"
      />
      <SaveAsProgramDialog
        isDialogOpen={saveAsProgramDialogOpen}
        setIsDialogOpen={setSaveAsProgramDialogOpen}
        createdIrrigation={savedIrrigation}
      />
    </>
  );
};

export default CreateIrrigationForm;

import { FC, useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import i18n from '@/i18n.ts';

const LanguageSwitcherDropdown: FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('🇷🇸');

  const handleLanguageChange = (locale: string, flag: string) => {
    setSelectedLanguage(flag);
    i18n.changeLanguage(locale);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="outline-none dark:outline-none">
        {selectedLanguage}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white">
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('sr', '🇷🇸')}
        >
          🇷🇸 Serbian
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('en', '🇬🇧')}
        >
          🇬🇧 English
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('es', '🇪🇸')}
        >
          🇪🇸 Spanish
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('de', '🇩🇪')}
        >
          🇩🇪 German
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('sk', '🇸🇰')}
        >
          🇸🇰 Slovak
        </DropdownMenuItem>
        <DropdownMenuItem
          className="hover:bg-[#2f333e] cursor-pointer"
          onClick={() => handleLanguageChange('sq', '🇦🇱')}
        >
          🇦🇱 Albanian
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSwitcherDropdown;

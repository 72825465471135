import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Program } from '@/redux/program/program.types.ts';

const programSliceInitialState = {
  programs: [],
};

const programSlice = createSlice({
  name: 'programSlice',
  initialState: programSliceInitialState,
  reducers: {
    setPrograms: (state, action: PayloadAction<Program[]>) => {
      state.programs = action.payload;
    },
    removeDeletedProgram: (state, action: PayloadAction<Program>) => {
      state.programs = state.programs.filter(
        (program) => program.id !== action.payload.id
      );
    },
  },
});

export const { setPrograms, removeDeletedProgram } = programSlice.actions;
export default programSlice.reducer;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSwitcher from '@/components/localization/LanguageSwitcher.tsx';

const LoginPageLeftSection: FC = () => {
  const { t } = useTranslation('translation');

  return (
    <div className="flex flex-col justify-center pl-5 bg-[#33b35a] md:w-[50%] max-md:p-5 w-full">
      <h1 className="text-4xl text-white font-bold">Smart Watering</h1>
      <p className="text-lg text-white font-light max-md:hidden">
        {t('welcome')}
      </p>
      <LanguageSwitcher />
    </div>
  );
};

export default LoginPageLeftSection;

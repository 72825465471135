import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { IrrigationFormData } from '@/components/forms/CreateIrrigationForm.tsx';
import {
  removeIrrigationById,
  setScheduledIrrigation,
} from '@/redux/irrigation/irrigation.slice.ts';
import {
  CreateIrrigationResponse,
  GetScheduledIrrigationResponse,
} from '@/redux/irrigation/irrigation.types.ts';
import axiosServer from '@/services/axios.service.ts';

const createIrrigation = createAsyncThunk<
  CreateIrrigationResponse,
  IrrigationFormData
>('user/create-irrigation', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateIrrigationResponse>(
      `/irrigation/create-irrigation`,
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getScheduledIrrigationAction = createAsyncThunk<
  GetScheduledIrrigationResponse,
  string
>('user/create-irrigation', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetScheduledIrrigationResponse>(
      `/irrigation/get-scheduled?parcelId=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setScheduledIrrigation(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteIrrigation = createAsyncThunk<CreateIrrigationResponse, string>(
  'user/delete-irrigation',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateIrrigationResponse>(
        `/irrigation/delete-scheduled?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(removeIrrigationById(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export { createIrrigation, getScheduledIrrigationAction, deleteIrrigation };

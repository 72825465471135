import { FC } from 'react';

import LoginForm from '@/components/forms/LoginForm.tsx';
import LoginPageLeftSection from '@/components/layout/login/LoginPageLeftSection.tsx';

const LoginPage: FC = () => {
  return (
    <div className="h-screen">
      <div className="bg-[url('/loginBg.jpg')] fixed top-0 left-0 w-full h-screen bg-cover bg-center bg-no-repeat -z-10 blur-[10px]" />
      <div className="flex flex-1 flex-row w-screen justify-center items-center h-full">
        <div className="flex flex-row max-md:flex-col max-md:items-center self-center md:min-w-[1000px] max-md:mx-5 max-md:w-full md:min-h-[800px]">
          <LoginPageLeftSection />
          <div className="md:w-[50%] max-md:w-full bg-white flex justify-center items-center">
            <LoginForm />
          </div>
        </div>
      </div>
      <footer className="sticky bottom-0 h-70px text-white text-center">
        <span>© 2024 Smart Watering</span>
      </footer>
    </div>
  );
};

export default LoginPage;

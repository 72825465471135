import { z } from 'zod';

export const loginSchema = z.object({
  email: z.string().email({
    message: 'Invalid email',
  }),
  password: z.string().min(1, {
    message: 'Password is mandatory',
  }),
});

export const forgotPasswordSchema = z.object({
  email: z.string().email({
    message: 'Invalid email',
  }),
});

export const resetPasswordSchema = z
  .object({
    token: z.string().min(1, 'Token is mandatory'),
    password: z
      .string()
      .min(8, 'Password must have at least 8 characters')
      .max(100, 'Password must not have more than 100 characters')
      .refine(
        (value) => /[a-z]/.test(value),
        'Password must have a least 1 lowercase letter'
      )
      .refine(
        (value) => /[A-Z]/.test(value),
        'Password must have at least 1 uppercase letter'
      )
      .refine(
        (value) => /\d/.test(value),
        'Password must have at least 1 number'
      )
      .refine(
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
        'Password must have at least 1 special character'
      ),
    confirmPassword: z.string().min(1, 'Confirm password is mandatory'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords are not matching',
    path: ['confirmPassword'],
  });

const basicIrrigationSchema = z.object({
  parcelId: z.string().min(1, 'Parcel ID is required'),
  irrigationType: z.enum(['time', 'm3'], {
    required_error: 'Irrigation type is required',
  }),
  dateAndTimeOfIrrigation: z.date().refine((date) => date >= new Date(), {
    message: 'Date and time of irrigation must not be in the past',
  }),
  workTimeOfIrrigation: z.coerce.number().optional(),
  shifts: z
    .array(
      z.object({
        shiftId: z.coerce.number(),
        zone: z.string(),
        waterLevel: z.coerce.number(),
      })
    )
    .optional(),
  mixerEnabled: z.boolean(),
  mixerStartDateTime: z.date().optional(),
  mixerWorkTime: z.coerce.number().optional(),
  supplementOneEnabled: z.boolean(),
  supplementOneDateTime: z.date().optional(),
  supplementOneWorkTime: z.coerce.number().optional(),
  pH: z.coerce.number().optional(),
  supplementTwoEnabled: z.boolean(),
  supplementTwoDateTime: z.date().optional(),
  supplementTwoWorkTime: z.coerce.number().optional(),
  supplementTwoEC: z.coerce.number().optional(),
  supplementThreeEnabled: z.boolean(),
  supplementThreeDateTime: z.date().optional(),
  supplementThreeWorkTime: z.coerce.number().optional(),
  supplementThreeEC: z.coerce.number().optional(),
  supplementFourEnabled: z.boolean(),
  supplementFourDateTime: z.date().optional(),
  supplementFourWorkTime: z.coerce.number().optional(),
  supplementFourEC: z.coerce.number().optional(),
});

const irrigationTypeRefinement = basicIrrigationSchema
  .refine(
    (data) => {
      return !(
        data.irrigationType === 'time' &&
        (!data.workTimeOfIrrigation || data.workTimeOfIrrigation <= 0)
      );
    },
    {
      path: ['workTimeOfIrrigation'],
      message:
        'Work time of irrigation must be a positive number greater than 0',
    }
  )
  .refine(
    (data) => {
      return !(
        data.irrigationType === 'm3' &&
        (!data.shifts || data.shifts.length === 0)
      );
    },
    {
      path: ['shifts'],
      message: 'You must add at least one shift',
    }
  );

const mixerEnabledRefinement = irrigationTypeRefinement
  .refine(
    (data) => {
      if (data.mixerEnabled) {
        if (!data.mixerStartDateTime || data.mixerStartDateTime < new Date()) {
          return false;
        }
      }
      return true;
    },
    {
      path: ['mixerStartDateTime'],
      message: 'Mixers starting date and time must not be in past',
    }
  )
  .refine(
    (data) => {
      if (data.mixerEnabled) {
        if (!data.mixerWorkTime || data.mixerWorkTime <= 0) {
          return false;
        }
      }
      return true;
    },
    {
      path: ['mixerWorkTime'],
      message: 'Mixers work time must be greater than 0',
    }
  );

const supplementOneRequiredFieldsRefinement = mixerEnabledRefinement.refine(
  (data) => {
    if (data.supplementOneEnabled) {
      return (
        data.supplementOneDateTime && data.supplementOneWorkTime && data.pH
      );
    }
    return true;
  },
  {
    path: ['supplementOneDateTime'],
    message: 'Supplement one start date, work time and pH are mandatory',
  }
);

const supplementOneDateTimeRefinement =
  supplementOneRequiredFieldsRefinement.refine(
    (data) => {
      if (data.supplementOneEnabled) {
        return (
          data.supplementOneDateTime >= data.dateAndTimeOfIrrigation &&
          data.supplementOneDateTime.getTime() +
            data.supplementOneWorkTime * 60000 <=
            data.dateAndTimeOfIrrigation.getTime() +
              data.workTimeOfIrrigation * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementOneDateTime'],
      message:
        'Supplement one must not start before irrigation and must not time longer then irrigation',
    }
  );

const supplementTwoRequiredFieldsRefinement =
  supplementOneDateTimeRefinement.refine(
    (data) => {
      if (data.supplementTwoEnabled) {
        return (
          data.supplementTwoDateTime &&
          data.supplementTwoWorkTime &&
          data.supplementTwoEC
        );
      }
      return true;
    },
    {
      path: ['supplementTwoDateTime'],
      message: 'Supplement two start date, work time and EC are mandatory',
    }
  );

const supplementTwoDateTimeRefinement =
  supplementTwoRequiredFieldsRefinement.refine(
    (data) => {
      if (data.supplementTwoEnabled) {
        return (
          data.supplementTwoDateTime >= data.dateAndTimeOfIrrigation &&
          data.supplementTwoDateTime.getTime() +
            data.supplementTwoWorkTime * 60000 <=
            data.dateAndTimeOfIrrigation.getTime() +
              data.workTimeOfIrrigation * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementTwoDateTime'],
      message:
        'Supplement one must not start before irrigation and must not time longer then irrigation',
    }
  );

const supplementThreeRequiredFieldsRefinement =
  supplementTwoDateTimeRefinement.refine(
    (data) => {
      if (data.supplementThreeEnabled) {
        return (
          data.supplementThreeDateTime &&
          data.supplementThreeWorkTime &&
          data.supplementThreeEC
        );
      }
      return true;
    },
    {
      path: ['supplementThreeDateTime'],
      message: 'Supplement three start date, work time and EC are mandatory',
    }
  );

const supplementThreeDateTimeRefinement =
  supplementThreeRequiredFieldsRefinement.refine(
    (data) => {
      if (data.supplementThreeEnabled) {
        return (
          data.supplementThreeDateTime >= data.dateAndTimeOfIrrigation &&
          data.supplementThreeDateTime.getTime() +
            data.supplementThreeWorkTime * 60000 <=
            data.dateAndTimeOfIrrigation.getTime() +
              data.workTimeOfIrrigation * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementThreeDateTime'],
      message:
        'Supplement three must not start before irrigation and must not time longer then irrigation',
    }
  );

const supplementFourRequiredFieldsRefinement =
  supplementThreeDateTimeRefinement.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return (
          data.supplementFourDateTime &&
          data.supplementFourWorkTime &&
          data.supplementFourEC
        );
      }
      return true;
    },
    {
      path: ['supplementFourDateTime'],
      message: 'Supplement four start date, work time and EC are mandatory',
    }
  );

const supplementFourDateTimeRefinement =
  supplementFourRequiredFieldsRefinement.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return (
          data.supplementFourDateTime >= data.dateAndTimeOfIrrigation &&
          data.supplementFourDateTime.getTime() +
            data.supplementFourWorkTime * 60000 <=
            data.dateAndTimeOfIrrigation.getTime() +
              data.workTimeOfIrrigation * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementFourDateTime'],
      message:
        'Supplement four must not start before irrigation and must not time longer then irrigation',
    }
  );

export const irrigationFormSchema = supplementFourDateTimeRefinement;

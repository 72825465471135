import { FC } from 'react';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog.tsx';

type ConfirmDialogProps = {
  headerText: string;
  descriptionText: string;
  onDiscardClick: () => void;
  onContinueClick: () => void;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  isDialogOpen: boolean;
  confirmText: string;
  discardText: string;
  confirmStyle: string;
};

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  headerText,
  descriptionText,
  onDiscardClick,
  onContinueClick,
  setIsDialogOpen,
  isDialogOpen,
  confirmText,
  discardText,
  confirmStyle,
}) => {
  return (
    <AlertDialog
      open={isDialogOpen}
      onOpenChange={() => setIsDialogOpen(false)}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{headerText}</AlertDialogTitle>
          <AlertDialogDescription>{descriptionText}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={onDiscardClick}
            className="bg-white py-3 px-5 text-black"
          >
            {discardText}
          </AlertDialogCancel>
          <AlertDialogAction onClick={onContinueClick} className={confirmStyle}>
            {confirmText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmDialog;

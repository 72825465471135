import { RootState } from '@/redux/store.ts';

export const getSelectedParcelId = (state: RootState) =>
  state.reducer.parcel.selectedParcelId;

export const getSelectedParcelName = (state: RootState) =>
  state.reducer.parcel.selectedParcelName;

export const getSelectedParcelPlace = (state: RootState) =>
  state.reducer.parcel.selectedParcelPlace;

export const getSelectedParcelNumberOfZones = (state: RootState) =>
  state.reducer.parcel.selectedParcelNumberOfZones;

export const getParcelsDropdownUsers = (state: RootState) =>
  state.reducer.parcel.userDropdownParcels;

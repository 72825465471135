import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './main.css';

import App from './App';
import store from './redux/store';

import { config } from '@/config/config.ts';

if (config.sentry.env !== 'development') {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/smartwatering\.store\/api/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: config.sentry.env,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

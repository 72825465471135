import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import sk from './locales/sk.json';
import sq from './locales/sq.json';
import sr from './locales/sr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    de: { translation: de },
    es: { translation: es },
    sk: { translation: sk },
    sq: { translation: sq },
    sr: { translation: sr },
  },
  lng: 'sr',
  fallbackLng: 'sr',
  ns: ['translation'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
